import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';
import Switch from 'rc-switch';
import swal from 'sweetalert2';
import moment from 'moment';

import 'sweetalert2/dist/sweetalert2.min.css';
import { Check } from 'react-feather';
import { ToastContainer, toast } from "react-toastify";
import configs from '../../../../config/urlConfigs.js';
const readCookie = require('../../../../cookie.js').readCookie;

export default class DeclareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attendanceValidation: false,

      
      toggleValue: true || (props.participantsData) ?
        (true) : ((!props.participants.hasOwnProperty('selectedOpportunity')) ?
          (true) :
          (!props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')
            ? (true) : ((props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')) ?
              ((!props.participants.selectedOpportunity.attendeeDeclaration.participated) ? (
                !props.participants.selectedOpportunity.attendeeDeclaration.participated) : (
                (props.participants.selectedOpportunity.attendeeDeclaration.participated))) : (true)))),
      friendsList: [],
      page: 0,


      start_time: (props.participantsData) ? (props.selectedOpportunityData?.startTime) : ((!props.participants.hasOwnProperty('selectedOpportunity')) ?
        (props.selectedOpportunityData.startTime) : (!props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')
          ? ("") : ((props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')) ?
            ((!props.participants.selectedOpportunity.attendeeDeclaration.participated) ? (props.participants.selectedOpportunity.startTime) : (
              props.participants.selectedOpportunity.attendeeDeclaration['startTime']
            )) : (props.participants.selectedOpportunity.startTime))))
        || props.participants.selectedOpportunity.startTime,

      end_time: (props.participantsData) ? (props.selectedOpportunityData.endTime) : ((!props.participants.hasOwnProperty('selectedOpportunity')) ?
        (props.selectedOpportunityData.endTime) :
        (!props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')
          ? (props.participants.selectedOpportunity.endTime) : ((props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')) ?
            ((!props.participants.selectedOpportunity.attendeeDeclaration.participated) ? (props.participants.selectedOpportunity.endTime) : (
              props.participants.selectedOpportunity.attendeeDeclaration['endTime']
            )) : (props.participants.selectedOpportunity.endTime))))
        || props.selectedOpportunityData.endTime
      ,


      experience: '' || (props.participantsData) ?
        (null) : ((!props.participants.hasOwnProperty('selectedOpportunity')) ?
          (null) :
          (!props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')
            ? (null) : ((props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')) ?
              ((!props.participants.selectedOpportunity.attendeeDeclaration.participated) ? (null) : (
                ((props.participants.selectedOpportunity.attendeeDeclaration['experience'])))) : (null)))),
      reason: "" || (props.participantsData) ?
        (null) : ((!props.participants.hasOwnProperty('selectedOpportunity')) ?
          (null) :
          (!props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')
            ? (null) : ((props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')) ?
              ((!props.participants.selectedOpportunity.attendeeDeclaration.participated) ? (null) : (
                ((props.participants.selectedOpportunity.attendeeDeclaration['reason'])))) : (null)))),


      error: [],

      questionSelectedOption1: null || ((props.participantsData) ? (null) :
        ((!props.participants.hasOwnProperty('selectedOpportunity')) ?
          (null) :
          (!props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')
            ? (null) : ((props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')) ?
              ((!props.participants.selectedOpportunity.attendeeDeclaration.participated) ? (null) : (
                ((props.participants.selectedOpportunity.attendeeDeclaration['questionSelectedOption1'] === "5" || "4" || "3" || "2" || "1") ?
                  (props.participants.selectedOpportunity.attendeeDeclaration['questionSelectedOption1']) : (null))))
              : (null))))),
      questionSelectedOption2: null || ((props.participantsData) ? (null) :
        ((!props.participants.hasOwnProperty('selectedOpportunity')) ?
          (null) :
          (!props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')
            ? (null) : ((props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')) ?
              ((!props.participants.selectedOpportunity.attendeeDeclaration.participated) ? (null) : (
                ((props.participants.selectedOpportunity.attendeeDeclaration['questionSelectedOption2'] === "5" || "4" || "3" || "2" || "1") ?
                  (props.participants.selectedOpportunity.attendeeDeclaration['questionSelectedOption2']) : (null))))
              : (null))))),
      questionSelectedOption3: null || ((this.props.participantsData) ? (null) :
        ((!props.participants.hasOwnProperty('selectedOpportunity')) ?
          (null) :
          (!props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')
            ? (null) : ((props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')) ?
              ((!props.participants.selectedOpportunity.attendeeDeclaration.participated) ? (null) : (
                ((props.participants.selectedOpportunity.attendeeDeclaration['questionSelectedOption3'] === "5" || "4" || "3" || "2" || "1") ?
                  (props.participants.selectedOpportunity.attendeeDeclaration['questionSelectedOption3']) : (null))))
              : (null))))),
              friendName :"",
              friendListChecker :[],
              displayRegistrations:false,
              friends: [{ id: 0 }],
              unitOfMeasurementanswer:null || ((this.props.participantsData) ? ('') :
              ((!props.participants.hasOwnProperty('selectedOpportunity')) ?
                ('') :
                (!props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')
                  ? ('') : ((props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')) ?
                    ((!props.participants.selectedOpportunity.attendeeDeclaration.participated) ? (null) : (
                      ((props.participants.selectedOpportunity?.attendeeDeclaration['unitOfMeasurementanswer']) ?
                        (props.participants.selectedOpportunity.attendeeDeclaration['unitOfMeasurementanswer']) : (''))))
                    : (''))))),
              allowedFriendsNo:0
    }
    this.handleChange = this.handleChange.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onOptionChange2 = this.onOptionChange2.bind(this);
    this.onOptionChange3 = this.onOptionChange3.bind(this);
    this.addFriends = this.addFriends.bind(this);
    this.handleAddFriends = this.handleAddFriends.bind(this);
		this.deleterow = this.deleterow.bind(this);

    // this.handleFriendsNameChange = this.handleFriendsNameChange.bind(this);
  }

  componentDidMount() {
   
    this.fetchFriendsList()
  }


  onChange(name, required, value) {
    let commentRegex = new RegExp(`^([\.a-zA-Z0-9,!?:;@#$~' ]*$)|(^$)`);

    if (name === 'toggleValue') {
      this.setState({ toggleValue: !this.state.toggleValue });
    } else {
      var error = this.state.error;
      var index = error.indexOf(name);
      if (name === 'start_time' || name === 'end_time') {
        if (required && value !== '' && index > -1) error.splice(index, 1);
        this.setState({ [name]: moment(value).format('HH:mm'), error });
      }
      else {
      //   if ((commentRegex.test(value.target.value) === false && true) && name === "experience" && required && value.target.value !== '' && index > -1) {error.splice(index, 1);}
      //   else if(name === "experience" && commentRegex.test(value.target.value) === true){
        if(name === "experience")  { this.setState({ [name]: value.target.value, error });
        }
        //     }
                 
      }
    }
  }

  onOptionChange(e) {
    this.setState({ questionSelectedOption1: e.target.value })
  }

  onOptionChange2(e) {
    this.setState({ questionSelectedOption2: e.target.value });

  }

  onOptionChange3(e) {
    this.setState({ questionSelectedOption3: e.target.value });

  }

  hours(start_time, end_time) {
    var endTime = moment(end_time, 'h:mm');
    var startTime = moment(start_time, 'h:mm');
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    return hours + ' hour and ' + minutes + ' minutes';
  }

  handleChange(e) {
    this.setState({ reason: e.target.value });
    this.onChange.bind(this, this.state.reason, true)
  }


  declare() {
    //   let voId =  CryptoJS.AES.encrypt( this.props.opportunityId, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    //   let participated = this.state.toggleValue;
    //   //  CryptoJS.AES.encrypt( this.state.toggleValue, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    //   let startTime =CryptoJS.AES.encrypt( this.state.start_time, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    //   let endTime = CryptoJS.AES.encrypt( this.state.end_time, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    //   let experience = CryptoJS.AES.encrypt( this.state.experience, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    //   let questionSelectedOption1 = CryptoJS.AES.encrypt( this.state.questionSelectedOption1, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    //   let questionSelectedOption2 = CryptoJS.AES.encrypt( this.state.questionSelectedOption2, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    //   let questionSelectedOption3 = CryptoJS.AES.encrypt( this.state.questionSelectedOption3, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    //  let friendsList =this.state.friendsList;
    let data = {}
    // let title = this.props.participants.selectedOpportunity.customUrl;
    // data['attendanceValidation'] = this.state.attendanceValidation;
    data['voId'] = this.props.opportunityId;
    data['participated'] = this.state.toggleValue;
    if (this.state.toggleValue) {
      data['startTime'] = this.state.start_time;
      data['endTime'] = this.state.end_time;
      data['experience'] = this.state.experience;
      data['questionSelectedOption1'] = this.state.questionSelectedOption1;
      data['questionSelectedOption2'] = this.state.questionSelectedOption2;
      data['questionSelectedOption3'] = this.state.questionSelectedOption3;
      data['friendsAttended'] = this.state.friendsList;
      data['unRegisteredfriendsCount'] = this.state.displayRegistrations && this.state.friends[0].name ? this.state.friends:[];
      data['unitOfMeasurementanswer']=this.state.unitOfMeasurementanswer
    } else {
      data['reason'] = this.state.reason;
    }
    if ((this.props.hasOwnProperty('participantsData') || this.props.hasOwnProperty('participants'))) {

      let error = [];
      if (this.state.toggleValue) {
        if (!this.state.start_time || this.state.start_time === "Invalid date") error.push('start_time');
        if (!this.state.end_time || this.state.end_time === "Invalid date") error.push('end_time');
        // if (!this.state.experience) error.push('experience');
        if (!this.state.questionSelectedOption1 || !this.state.questionSelectedOption2 || !this.state.questionSelectedOption3) {
          error.push('option');
        }
        if (this.state.start_time && this.state.end_time) {
          let start_time_to_check = moment().hour(this.state.start_time.split(":")[0]).minute
            (this.state.start_time.split(":")[1]).toDate(), end_time_to_check = moment().hour(this.state.end_time.split(":")[0]).minute
              (this.state.end_time.split(":")[1]).toDate();
          if (start_time_to_check > end_time_to_check) {
            error.push('start_time_error')
          }
        }
      } else {
        if (!this.state.reason) error.push("reason");
      }
      this.setState({ error });
      if ((error.length === 0)) {
            let friendsAttended = 0 ;
           let totalFriends =  this.state.friendsList.length === 0 ? this.state.friends : 
           (this.state.friends[0].name ? [...this.state.friendsList,...this.state.friends] : this.state.friendsList) ;
              if(totalFriends.length > 0 ){
                totalFriends.map((friend)=>{
                      if(friend.attended){
                        friendsAttended++;
                      }
                })
              }
        fetch(
          configs.BACKEND_API_URL + '/ee/volunteer-opportunity/attendee-declaration',
          {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Auth': JSON.parse(readCookie('access_token')).access_token
            },
            body: JSON.stringify(data)
          }).then(function (response) {
            return response.json();
          }).then(function (data) {
            if (data.status === 'ok' && this.state.toggleValue) {

              const guestData ={
                data : this.state.friendsList.length === 0 ? (this.state.friends[0].name && this.state.friends) : 
                (this.state.friends[0].name ? [...this.state.friendsList,...this.state.friends] : this.state.friendsList),
                isDeclare: true 
              }
                
          fetch( configs.BACKEND_API_URL + '/ee/friends-list/total/' +
           this.props.opportunityId,
          {
            method: "PUT",
            headers: {
              'Content-Type': 'application/json',
              'Auth': JSON.parse(readCookie('access_token')).access_token},
            body:JSON.stringify( guestData),
          }).then(function (response) {
            return response.json();
          }).then(function (data) {
            // this.props.getAllData();
          }.bind(this))
              var that = this;
              if(this.state.friendsList.length > 0 && friendsAttended > 0 ){
                swal({title : 'Awesome!',
                html : `<p>Thank you for spending your precious  ${this.hours(this.state.start_time || this.props.participants.
                 selectedOpportunity.attendeeDeclaration['startTime'], this.state.end_time || this.props.participants.
                 selectedOpportunity.attendeeDeclaration['endTime'])} ${" "}  towards voluntary contribution to the society</p>`,
                 showCancelButton: true,
                 cancelButtonColor: configs.THEME_COLOR,
                 confirmButtonText : `<p className="btn-contribute-photo btn-primary btn-declare-attendance" > Upload event photos</p>`,
                 cancelButtonText: `<p  className="btn-contribute-photo btn-primary btn-declare-attendance" >I'll upload later</p>`,
               }).then(
               function() {
                 window.location.href = `/my-volunteer-history/${that.props.participants.selectedOpportunity.customUrl}#gallery`;
                 window.location.reload();
                 that.props.hideDeclareModal()

               }, function(dismiss) {
                 if (dismiss === 'cancel') {
                   // console.log("dismiss",dismiss);
                   that.props.hideDeclareModal();
                   window.location.reload();
                 }
               });
              }
              else {
                swal({title : 'Awesome!',
                html : `<p>Thank you for spending your precious  ${this.hours(this.state.start_time || this.props.participants.
                 selectedOpportunity.attendeeDeclaration['startTime'], this.state.end_time || this.props.participants.
                 selectedOpportunity.attendeeDeclaration['endTime'])} ${" "}  towards voluntary contribution to the society</p>
                 <note> You have not marked the attendance of your family/friends/guests. If they attended the event, kindly check the boxes against their names.</note>`,
                 showCancelButton: true,
                 cancelButtonColor: configs.THEME_COLOR,
                 confirmButtonText : `<p className="btn-contribute-photo btn-primary btn-declare-attendance" > Upload event photos</p>`,
                 cancelButtonText: `<p  className="btn-contribute-photo btn-primary btn-declare-attendance" >I'll upload later</p>`,
               }).then(
               function() {
                // console.log("that.props.participants.selectedOpportunity.title",that.props.participants.selectedOpportunity.customUrl);
                // console.log("`/opportunity/${this.props.participants.selectedOpportunity.title}#gallery`",`/opportunity/${this.props.participants.selectedOpportunity.customUrl}#gallery`);
                 window.location.href = `/my-volunteer-history/${this.props.participants.selectedOpportunity.title}#gallery`;
                 window.location.reload();
                 that.props.hideDeclareModal()
               }, function(dismiss) {
                 if (dismiss === 'cancel') {
                   // console.log("dismiss",dismiss);
                   that.props.hideDeclareModal();
                   window.location.reload();
                 }
               });
              }
              
            }
            else if (data.status == 'ok' && !this.state.toggleValue) {
              swal(
                "Thank you ",
                "Thank you for letting us know"
              ).then(() => {
                this.props.hideDeclareModal()
                window.location.reload();
              });
            }
            else if(data.status=='error'){
              swal({
                title: data.message,
                type: "error",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result) {
                  window.location.reload()
                }
              }).catch(() => { });
        
               }
          }.bind(this));
      }
    }

    else {
      let error = [];
      if (this.state.toggleValue) {
        if (!this.state.start_time) error.push('start_time');
        if (!this.state.end_time) error.push('end_time');
        // if (!this.state.experience) error.push('experience');
        if (!this.state.questionSelectedOption1 || !this.state.questionSelectedOption2 || !this.state.questionSelectedOption3) {
          error.push('option');
        }
        if (this.state.start_time && this.state.end_time) {
          let start_time_to_check = moment().hour(this.state.start_time.split(":")[0]).minute
            (this.state.start_time.split(":")[1]).toDate(), end_time_to_check = moment().hour(this.state.end_time.split(":")[0]).minute
              (this.state.end_time.split(":")[1]).toDate();
          if (start_time_to_check > end_time_to_check) {
            error.push('start_time_error')
          }
        }
      } else {
        if (!this.state.reason) error.push("reason");
      }
      this.setState({ error });
      if (((error.length === 0 && this.props.participants.selectedOpportunity.hasOwnProperty('attendeeDeclaration')))) {
        const attendeedata = this.props.participants.selectedOpportunity.attendees.filter((data) => data.id === JSON.parse
          (readCookie('userData')).id)
        if (attendeedata[0]['declared'] != "" && attendeedata[0]['updatedParticipatedAttendence'] === "") {

          fetch(
            configs.BACKEND_API_URL + '/ee/volunteer-opportunity/attendee-declaration',
            {
              method: "PUT",
              headers: {
                'Content-Type': 'application/json',
                'Auth': JSON.parse(readCookie('access_token')).access_token
              },
              body: JSON.stringify(data),
            })
            .then(function (response) {
              return response.json();
            }).then(function (data) {
                   
              if (data.status === 'ok' && this.state.toggleValue || this.props.participants.
                selectedOpportunity.attendeeDeclaration['participated']) {
                  var that = this;
                  swal({title : 'Awesome!',
                  html : `<p>Thank you for spending your precious  ${this.hours(this.state.start_time || this.props.participants.
                   selectedOpportunity.attendeeDeclaration['startTime'], this.state.end_time || this.props.participants.
                   selectedOpportunity.attendeeDeclaration['endTime'])} ${" "}  towards voluntary contribution to the society</p>
                   <note> You have not marked the attendance of your family/friends/guests. If they attended the event, kindly check the boxes against their names</note>
                   `,
                   showCancelButton: true,
                   cancelButtonColor: configs.THEME_COLOR,
                   confirmButtonText : `<p className="btn-contribute-photo btn-primary btn-declare-attendance" > Upload event photos</p>`,
                   cancelButtonText: `<p  className="btn-contribute-photo btn-primary btn-declare-attendance" >I'll upload later</p>`,
                 }).then(
                 function() { 
                  // console.log("that.props.participants.selectedOpportunity.title",that.props.participants.selectedOpportunity.customUrl);
                  // console.log("That opportunity == >> ",`/opportunity/${this.props.participants.selectedOpportunity.customUrl}#gallery`)
                   window.location.href = `/my-volunteer-history/${that.props.participants.selectedOpportunity.customUrl}#gallery`;
                   that.props.hideDeclareModal();
                   window.location.reload();
                 }, function(dismiss) {
                   if (dismiss === 'cancel') {
                     // console.log("dismiss",dismiss);
                     that.props.hideDeclareModal();
                    //  window.location.reload();
                   }
                 });
              } else if (data.status == 'ok' && (!this.state.toggleValue || !this.props.participants.
                selectedOpportunity.attendeeDeclaration['participated']) && (this.state.start_time === "")) {
                swal(
                  "Thank you ",
                  "Thank you for letting us know"
                )
                this.props.hideDeclareModal()
                window.location.reload();
              }
            }.bind(this));

        }
      }
    }
  }

  fetchFriendsList() {
    fetch(
      configs.BACKEND_API_URL + '/ee/friends-list/' + this.props.opportunityId,
      {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Auth': JSON.parse(readCookie('access_token')).access_token
        },
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
              data.friends.map((d,index)=>{
              if(d.hasOwnProperty('attended')){
                d['attended'] = d.attended;
                return d ;
              }else {
                // console.log("friends",d);
                   d['attended'] = true;
                   return d 
              }
             })
            this.setState({ friendsList: data.friends });
            // console.log(this.props.participants?.selectedOpportunity,data.friends.length,this.props.participants?.selectedOpportunity.attendees.length , this.props.participants?.selectedOpportunity.friends.length ,parseInt(this.props.participants?.selectedOpportunity.registrationsAllowed));
            if (parseInt(this.props.participants?.selectedOpportunity.registrationsAllowed) > 0) {
              if ((this.props.participants?.selectedOpportunity.volunteersRequired+(data.friends.length||0))- (this.props.participants?.selectedOpportunity.attendees.length + this.props.participants?.selectedOpportunity.friends.length) > parseInt(this.props.participants?.selectedOpportunity.registrationsAllowed)) {
                this.setState({ allowedFriendsNo: parseInt(this.props.participants?.selectedOpportunity.registrationsAllowed)-(data.friends.length||0) })
              } else {
                this.setState({ allowedFriendsNo: this.props.participants?.selectedOpportunity.volunteersRequired - (this.props.participants?.selectedOpportunity.attendees.length + this.props.participants?.selectedOpportunity.friends.length)  })
              }
            }

      }.bind(this))
  }

  handleFriendsAttended(index) {
    var friends = this.state.friendsList;
    if (friends[index].hasOwnProperty('attended')) {
      friends[index].attended = !friends[index].attended
    } else {
      friends[index]['attended'] = true
    }
    this.setState({ friendsList: friends });
  }

  handleNewFriendsAttended(index){
    var friends = this.state.friends;
    if (friends[index].hasOwnProperty('attended')) {
      friends[index].attended = !friends[index].attended
    } else {
      friends[index]['attended'] = true
    }
    this.setState({ friends: friends });

  }
  


  handleFriendsNameChange(index,e){
    var friends = this.state.friendsList;
     friends[index].name = e.target.value
    this.setState({friendsList :friends}) 
  }
  // <<<<<<<<<  ====      adding a guest  ==== >>>>>>>>>>>>>>

  friendsDetailsAdded() {
    const index = this.state.friends.length - 1;
    // this.setState({currentIndex:index});
    if (this.state?.friends[index]?.name && this.state?.friends[index]?.gender && this.state?.friends[index]?.age) {
         this.state.friends[index]['refferedBy'] = JSON.parse(readCookie('userData')).id;
         this.state.friends[index]['opportunityId'] = this.props.opportunityId;
  
      return true
    } else return false
  }

  addFriends() {
    // const data = this.state.friends.filter(x => !x._id);

    if (this.friendsDetailsAdded()) {
          var addRow = [];
          addRow = this.state.friends;
          addRow.push({ id: this.state.friends.length });
          this.setState({friends:addRow});
    }
  
  }
  deleterow(id) {
    // console.log(_id,"Idd")
    
    // if (id === 0) {
    //   this.setState({ displayRegistrations: false, friends: [{ id: 0 }] })
    // }
    // else {
      var delrow = [{ id: 0 }];
      delrow = this.state.friends;
      delrow.splice(id, 1)
      this.setState({ friends: delrow })
    // }
  }
  handleAddFriends(index, value, key) {
    let commentRegex = new RegExp(`^[\.a-zA-Z0-9,!?:;@#$~' ]*$`);
    if (commentRegex.test(value) === true || true){
    if (value) {
      var friends = this.state.friends;
      friends[index][key] = value
      this.setState({ friends });
    }
  }
  }
  
  
  
  
  handleAddedNewFriendsAttended(index) {
    var friends = this.state.friends;
      // if (this.props.attendeeId) {
        if (friends[index].hasOwnProperty('attended')  ){
          this.state.friends[index].attended = !this.state.friends[index].attended
        } else {
          friends[index]['attended'] = true
        }
  this.setState({ friends })
  // }
  
  
  }


  render() {
    return (
      <Modal bsSize="lg" className="declare-modal" style={{ width: '100%' }}
        show={this.props.showDeclareModalVisibility} onHide={this.props.hideDeclareModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-header-custom">
            Declare Your Volunteer Hours
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: 500, overflowY: 'scroll', overflowX: 'hidden' }}>
            <Row>
              <Col md={12}>
                <form id="edit-org-details-modal-form"
                  // ref="vForm"
                >
                 {this.props.allowfeedback && <Col md={12}>
                    <div className="ee-recommend-switch-container">
                      <span className="ee-recommend-switch-left-text">I missed this opportunity</span>
                      <Switch onChange={this.onChange.bind(this, 'toggleValue')}
                        disabled={this.state.disabled} checked={this.state.toggleValue} />
                      <span className="ee-recommend-switch-right-text">I took part in this opportunity</span>
                    </div>
                  </Col>
                    }

                  {this.state.toggleValue ? <>
                  {this.props.enableParticipantsForTimeChange ?   <Col md={12}>
                      <Col className="form-group" md={3} mdOffset={3}>
                        <label className="control-label is-imp">Start Time: </label>

                        <TimePicker defaultValue=
                          {this.state.start_time ? moment().hour(this.state.start_time.split(":")[0]).minute
                            (this.state.start_time.split(":")[1]) : ""}
                          className="form-control" showSecond={false}
                          onChange={this.onChange.bind(this, "start_time", true)} 
                          disabled={this.props.enableParticipantsForTimeChange ? false:true } 
                          />
                        {this.state.error.indexOf('start_time')
                          > -1 ? (
                          <span className="red-color">Incorrect Time!</span>
                        ) : (null)}
                        {this.state.error.indexOf('start_time_error') > -1 ? (<span className="red-color">Incorrect Time!</span>) : (null)}
                      </Col>

                      <Col className="form-group" md={3}>
                        <label className="control-label is-imp">End Time: </label>

                        <TimePicker
                          defaultValue={this.state.end_time ? moment().hour(this.state.end_time.split(":")[0]).minute
                            (this.state.end_time.split(":")[1]) : ""}
                          className="form-control" showSecond={false} 
                          onChange={this.onChange.bind(this, "end_time", true)} 
                          disabled={this.props.enableParticipantsForTimeChange ?false :true}
                          />
                        {this.state.error.indexOf('end_time') > -1 ? (
                          <span className="red-color">Incorrect Time!</span>
                        ) : (null)}
                        {this.state.error.indexOf('end_time_error') > -1 ? (<span className="red-color">End time can't be smaller than start Time</span>) : (null)}
                      </Col>
                    </Col>: null}

                    <div style={{ marginBottom: 20 }}>

                      {this.state.friendsList.length > 0 ? <><Row>
                        <Col md={6} sm={6} mdOffset={3}>
                          <label className="control-label" style={{ fontWeight: 'bold', textDecoration: 'underline' }} htmlFor='friendsName' >Check the boxes against your guests who attended the event.</label>
                        </Col>
                      </Row><Row><Col md={6} sm={6} mdOffset={3}><note>Names will appear on the certificate as above. Correct the names if necessary.</note></Col></Row></> : null}

                      {this.state.friendsList.map((friend, index) => <Row>
                        <Col md={5} sm={5} xs={8} mdOffset={3}>
                          <div className="form-group pb0 mb0" >
                            <input type="text" className="form-control" 
                              style={{ margin: 0 }}  
                              value={this.state.friendsList[index].name} 
                              onChange={this.handleFriendsNameChange.bind(this,index)}
                            />
                          </div>
                        </Col>
                        <Col md={1} sm={1} xs={1} style={{ padding: 0 }}>
                          <div class="form-group form-check checkbox-row" style={{ margin: 0 }}>
                            <a className={friend.attended ? 'checkbox-active mt10' : 'checkbox-inactive mt10'} 
                            onClick={() => this.handleFriendsAttended(index)}>
                              <Check color='white' style={{ alignSelf: 'center' }} strokeWidth={3} size={12} />
                            </a>
                          </div>
                        </Col>
                      </Row>)}
                    </div>
                    
    {/* Adding a feature of adding a friend durign declaratio of thier Attendance */}
    {/* {console.log("this.props == >>> ",this.props,"volounrtnmr,m == >>> ",Number .parseInt((this.props?.selectedOpportunityData?.volunteersRequired || this.props?.participants?.selectedOpportunity?.volunteersRequired) - Number.parseInt((this.props?.selectedOpportunityData?.attendees?.length || this.props?.participants?.selectedOpportunity?.attendees.length ) + (this.props?.selectedOpportunityData?.friends.length  || this.props?.participants?.selectedOpportunity?.friends.length))))  } */}
                         
                          
                          { 
                            ((this.props?.selectedOpportunityData?.volunteersRequired || this.props?.participants?.selectedOpportunity?.volunteersRequired) - ((this.props?.selectedOpportunityData?.attendees?.length || this.props?.participants?.selectedOpportunity?.attendees.length ) +(this.props?.selectedOpportunityData?.friends.length  || this.props?.participants?.selectedOpportunity?.friends.length))   ) >0
                            && (( this.props?.selectedOpportunityData?.registrationsAllowed || this.props.participants.selectedOpportunity.registrationsAllowed) > this.state.friendsList.length )
                            ? 
                <Row style={{marginTop:"20px",marginRight:"27%"}}>
                  <Col md={6} sm={6} mdOffset={3} >
									<div className="form-group mb-0">
										<input type="checkbox" className="" id="displayRegistrations" 
                    checked={this.state.displayRegistrations} required 
                    onChange={() => this.setState({ displayRegistrations: !this.state.displayRegistrations })}  
                    />
										<label className="checkbox-label ml10" htmlFor='displayRegistrations'>Add friend(s)/family member(s) to this event.</label>
										</div>
                    </Col>
                    </Row> 
                    :
                    <note>We are sorry! There are no slots available to reserve for your friends/family members.</note>
								}
   	{this.state.displayRegistrations && ( this.props?.selectedOpportunityData?.registrationsAllowed || this.props?.participants?.selectedOpportunity?.registrationsAllowed) > this.state.friendsList.length && this.state.friends.length > 0 &&  <>
									<note style={{fontWeight:700 , marginLeft:20 }}>Note: Kindly do not add yourself as a guest.</note>
                  <div style={{ marginLeft:'20px' }}>
									<Row >
										<Col md={4} sm={4} xs={4} style={{ padding: 0 }}>
											<label className="control-label is-imp ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Name</label>
										</Col>
										<Col md={3} sm={6} xs={3} style={{ padding: 0 }}>
											<label className="control-label is-imp ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsGender' >Gender</label>
										</Col>
										<Col md={3} xs={3} sm={6} style={{ padding: 0 }}>
											<label className="control-label is-imp ml5" style={{ fontWeight: 'bold' }} htmlFor='friendsName' >Age</label>
										</Col>
										{this.state.friends.map((friend, index) => <>
											<Col md={4} sm={4} xs={4} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0" >
													<input type="text" className="form-control" required value={friend.name} onChange={(e) => this.handleAddFriends(index, e.target.value, 'name')} />
												</div>
											</Col>

											<Col md={3} sm={3} xs={3} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0">
													<select className="form-control" required value={friend.gender} onChange={(e) => this.handleAddFriends(index, e.target.value, 'gender')}>
														<option selected disabled>Gender</option>
														<option value="Male">Male</option>
														<option value="Female">Female</option>
														<option value="Prefer not to say">Prefer not to say</option>
													</select>
												</div>
											</Col>
											<Col md={2} sm={2} xs={2} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0">
													<input
														type="number"
														className="form-control"
														required
														value={friend.age}
														max={80} min={5}
														onChange={(e) => (e.target.value > 4 && e.target.value < 81) && this.handleAddFriends(index, parseInt(e.target.value), 'age')}
													/>
												</div>
											</Col>
                      <Col md={1} sm={1} xs={1} style={{ padding: 0 }}>
                                      <div
                                        class="form-group form-check checkbox-row"
                                        style={{ marginLeft: 10 }}
                                      >
                                        <a
                                          className={ friend.attended  ? "checkbox-active mt10" : "checkbox-inactive mt10" }
                                          onClick={() =>
                                            this.handleNewFriendsAttended(index)
                                          }
                                        >
                                          <Check color="white" style={{ alignSelf: "center" }} strokeWidth={3} size={12} />
                                        </a>
                                      </div>
                                    </Col>
											<Col md={1} sm={1} xs={1} style={{ padding: 0 }}>
												<div className="form-group pb0 mb0">
													<button className='btn btn-primary' style={{ marginLeft: 5 }} onClick={() => this.deleterow(index)}>
														<i className="fa fa-trash"></i>
													</button>
												</div>
											</Col>
										</>)}
									</Row>

                  </div>
									{ (this.state.allowedFriendsNo-(this.state.friends.length )) > 0
                  ? <div align='right' className='mt10' style={{marginRight:"10%"}}>
										<button className={`btn ${this.friendsDetailsAdded() ? 'btn-primary' : 'btn-primary-disabled'} btn-sm`} onClick={this.addFriends}>
											<i className="fa fa-plus"></i> add
										</button> </div> : null }
								</>}
                {this.props.allowfeedback && this.props.volunteerOpportunity && this.props.volunteerOpportunity?.addUnitOfMeasurement=='Yes' && 
                    <>
                     <div style={{
                      margin:'0px 20px 50px 20px',
                      display:'flex',
                      flexDirection:'column'
                     }} >
                      <label className="control-label declare-modal-question is-imp">
                        {/* Is there anything else you'd like for us to know about your volunteer experience? */}
                          {this.props.volunteerOpportunity.unitOfMeasurement}
                        </label>
                      <input style={{
                        maxWidth:400
                      }} placeholder="Enter the value"
                        className="form-control" id="experience" value={this.state.unitOfMeasurementanswer} onChange={e=>this.setState({
                          unitOfMeasurementanswer:e.target.value
                        })} ></input>
                      {this.state.error.indexOf('experience') > -1 ? (
                        // <span className="red-color">Incorrect Experience!</span>
                        <span className="red-color">Only letters and numbers are allowed.</span>
                      ) : (null)}
                    </div>
                    </>
                    }

                   {this.props.allowfeedback && <div style={{
                      width:'-webkit-fill-available',
                      margin:'0px 20px 50px 20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      alignSelf:'center',
                      height:0.5,
                      background:'gray'
                    }} />}

                    {this.props.allowfeedback &&
                      <>
                    <Col className='declare-modal-volunteer-work'>
                      <p className='ee-page-title' style={{ color: 'black', fontWeight: 'normal' }}>Tell Us About Your Volunteer Work</p>
                    </Col>
                    <Col md={12}>
                      <Row >
                        <Col md={5} lg={5}></Col>
                        <Col md={7}>
                          <Row>
                            <Col md={7}>
                              <Row>
                                <Col md={4} className="declare-modal-question declare-modal-question-1">Very much</Col>
                                <Col md={4} className="declare-modal-question declare-modal-question-1">Somewhat</Col>
                                <Col md={4} className="declare-modal-question declare-modal-question-1">Neutral</Col>
                              </Row>
                            </Col>
                            <Col md={5}>
                              <Row>
                                <Col md={6} className="declare-modal-question declare-modal-question-1">Not much</Col >
                                <Col md={6} className="declare-modal-question declare-modal-question-1">Not at all</Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row sm={'auto'}>
                        <Col md={5} lg={5} className='control-label is-imp declare-modal-question'>
                          How much of an impact do you feel your volunteer work had?</Col>
                        <Col md={7}>
                          <form onChange={this.onOptionChange}>
                            <Row>
                              <Col md={7}>
                                <Row>
                                  <Col md={4} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view '>
                                      <input type="radio" className='declare-modal-radio'

                                        aria-label="..."
                                        name="radio" value={5}
                                        checked={this.state.questionSelectedOption1 === "5"}
                                      />
                                      <div  className="mobile-view-option-name">Very much</div>
                                      </div>
                                  </Col>
                                  <Col md={4} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view' >
                                    <input type="radio" className='declare-modal-radio' aria-label="..."
                                      name="radio" value={4}
                                      checked={this.state.questionSelectedOption1 === "4"} />
                                      <div  className="mobile-view-option-name" >Somewhat</div>
                                  </div></Col>
                                  <Col md={4} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view' >
                                    <input type="radio" className='declare-modal-radio'

                                      aria-label="..."
                                      name="radio" value={3}
                                      checked={this.state.questionSelectedOption1 === "3"} />
                                      <div  className="mobile-view-option-name">Neutral</div>
                                      </div></Col>
                                </Row>
                              </Col>
                              <Col md={5}>
                                <Row>
                                  <Col md={6} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio' aria-label="..."
                                      name="radio" value={2} checked={this.state.questionSelectedOption1 === "2"}
                                    />
                                    <div  className="mobile-view-option-name">Not much</div>
                                    </div></Col >
                                  <Col md={6} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view' >
                                    <input type="radio" className='declare-modal-radio'
                                      aria-label="..."
                                      name="radio" value={1}
                                      checked={this.state.questionSelectedOption1 === "1"}
                                    />
                                    <div  className="mobile-view-option-name">Not at all</div>
                                    </div></Col>
                                </Row>
                              </Col>
                            </Row>
                          </form>
                        </Col>

                      </Row>
                      <Row sm={'auto'}>
                        <Col md={5} lg={5} className='control-label is-imp declare-modal-question'>   How satisfied are you with your volunteer experience?</Col>
                        <Col md={7}>
                          <form onChange={this.onOptionChange2}>
                            <Row>
                              <Col md={7}>
                                <Row>
                                  <Col md={4} className="declare-modal-question ">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view' >
                                      <input type="radio" className='declare-modal-radio'

                                        aria-label="..."
                                        name="radio" value={5}
                                        checked={this.state.questionSelectedOption2 === "5"}
                                      />
                                       <div  className="mobile-view-option-name">Very much</div>
                                        </div>
                                  </Col>
                                  <Col md={4} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio' aria-label="..."
                                      name="radio" value={4}
                                      checked={this.state.questionSelectedOption2 === "4"}
                                    /><div  className="mobile-view-option-name" >Somewhat</div></div></Col>
                                  <Col md={4} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio'

                                      aria-label="..."
                                      name="radio" value={3}
                                      checked={this.state.questionSelectedOption2 === "3"}
                                    /><div  className="mobile-view-option-name">Neutral</div>
                                    
                                    </div></Col>
                                </Row>
                              </Col>
                              <Col md={5}>
                                <Row>
                                  <Col md={6} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio'

                                      aria-label="..."
                                      name="radio" value={2}
                                      checked={this.state.questionSelectedOption2 === "2"}
                                    /><div  className="mobile-view-option-name">Not much</div></div></Col >
                                  <Col md={6} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio'

                                      aria-label="..."
                                      name="radio" value={1}
                                      checked={this.state.questionSelectedOption2 === "1"}
                                    /><div  className="mobile-view-option-name">Not at all</div></div></Col>
                                </Row>
                              </Col>
                            </Row>
                          </form>
                        </Col>
                      </Row>
                      <Row sm={'auto'}>
                        <Col md={5} lg={5} className='control-label is-imp declare-modal-question' >  How likely are you to participate in a similar event again?</Col>
                        <Col md={7}>
                          <form onChange={this.onOptionChange3}>
                            <Row>
                              <Col md={7}>
                                <Row>
                                  <Col md={4} className="declare-modal-question">
                                    <div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                      <input type="radio"

                                        className='declare-modal-radio'
                                        aria-label="..."
                                        name="radio" value={5}
                                        checked={this.state.questionSelectedOption3 === "5"}
                                      />
                                       <div  className="mobile-view-option-name">Very much</div>
                                      </div>
                                  </Col>
                                  <Col md={4} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio' aria-label="..."
                                      name="radio" value={4} checked={this.state.questionSelectedOption3 === "4"}
                                    />
                                    <div  className="mobile-view-option-name" >Somewhat</div>
                                    </div>
                                    </Col>
                                  <Col md={4} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio' aria-label="..." name="radio" value={3}
                                      checked={this.state.questionSelectedOption3 === "3"}
                                    /><div  className="mobile-view-option-name">Neutral</div>
                                    </div></Col>
                                </Row>
                              </Col>
                              <Col md={5}>
                                <Row>
                                  <Col md={6} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio'

                                      aria-label="..."
                                      name="radio" value={2}
                                      checked={this.state.questionSelectedOption3 === "2"}
                                    /><div  className="mobile-view-option-name">Not much</div>
                                    </div>
                                    </Col >
                                  <Col md={6} className="declare-modal-question"><div className='declare-modal-radio-div declare-modal-radio-div-mobile-view'>
                                    <input type="radio" className='declare-modal-radio'
                                      aria-label="..."
                                      name="radio" value={1}
                                      checked={this.state.questionSelectedOption3 === "1"}
                                    /><div  className="mobile-view-option-name">Not at all</div></div></Col>
                                </Row>
                              </Col>
                            </Row>
                          </form>
                        </Col></Row>
                    </Col>
                    </>}
                    {this.state.error.indexOf('option')
                      > -1 ? (
                      <span className="red-color" style={{ marginTop: 25, marginLeft: 14 }}>Make sure you've filled valid feedback</span>
                    ) : (null)}
                 
                    {this.props.allowfeedback && <Col className="form-group" md={12}>
                      <label className="control-label declare-modal-question is-imp">
                        {/* Is there anything else you'd like for us to know about your volunteer experience? */}
                        Let us know what you thought of the activity and your volunteer experience
                        </label>
                      <textarea placeholder="You may want to highlight your favorite parts of the volunteering experience, the difference you created, what could be improved, etc."
                        className="form-control" id="experience" value={this.state.experience} onChange={this.onChange.bind(this, "experience", true)} ></textarea>
                      {this.state.error.indexOf('experience') > -1 ? (
                        // <span className="red-color">Incorrect Experience!</span>
                        <span className="red-color">Only letters ,numbers and special characters are allowed.</span>
                      ) : (null)}
                    </Col>}
                  </>
                    :
                    <Col md={12}>
                      <Col className="form-group" md={12}>
                        <Row>
                          <Col md={7}>
                            <label className="control-label is-imp">
                              Oh no! Please select the reason for missing this opportunity
                            </label>
                          </Col>
                          <Col md={3} className="selected-option">
                            <select id="type" defaultValue={this.state.reason} onChange={this.handleChange}>
                              <option value="">Choose one</option>
                              <option value="Got engaged with another official work">Got engaged with another official work </option>
                              <option value="Got engaged with another unofficial work">Got engaged with another unofficial work</option>
                              <option value="Had an emergency situation">Had an emergency situation</option>
                              <option value="Forgot about the event">Forgot about the event</option>
                              <option value="Opted for another event">Opted for another event</option>
                              <option value="Another Reason">Another Reason</option>
                            </select>
                            {this.state.error.indexOf('reason') > -1 ? (<span className="red-color">Incorrect Reason!</span>) : (null)}
                          </Col>
                        </Row>
                      </Col>
                    </Col>}
                </form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer-center">
   {this.state.toggleValue ? ( this.state.experience && this.state.questionSelectedOption1 &&
       this.state.questionSelectedOption2 && this.state.questionSelectedOption3 &&
        (this.props.volunteerOpportunity?.addUnitOfMeasurement!=='Yes'|| this.state.unitOfMeasurementanswer)? 
       <Button className="btn btn-primary" onClick={this.declare.bind(this)}>
       Submit Your Declaration
     </Button> :     <Button className="btn btn-primary" disabled onClick={this.declare.bind(this)}>
            Submit Your Declaration
          </Button>     ) : (this.state.reason ? <Button className="btn btn-primary" onClick={this.declare.bind(this)}>
       Submit Your Declaration
     </Button> :     <Button className="btn btn-primary" disabled onClick={this.declare.bind(this)}>
            Submit Your Declaration
          </Button> ) }

          {/* <Button className="btn btn-primary" onClick={this.declare.bind(this)}>
            Submit Your Declaration
          </Button> */}
          <Button className="btn btn-outline-primary" onClick={this.props.hideDeclareModal.bind(this)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}